import { useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';

//Prime React Component Inbuilt
import { Column } from 'primereact/column';
import { DataTable } from 'primereact/datatable';
import { InputText } from 'primereact/inputtext';
import { Toast } from 'primereact/toast';
import { Toolbar } from 'primereact/toolbar';
import { Dropdown } from 'primereact/dropdown';
import { BreadCrumb } from 'primereact/breadcrumb';
import { Avatar } from 'primereact/avatar';
import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog';

import moment from "moment/moment";

// Column
import { ApplicantTrashColumns } from '../../../appconfig/DatatableSetting';

// Data table
import {
    defaultPageRowOptions,
    defaultRowOptions,
    paginatorLinks,
    showingEntries,
    SkeletonbodyTemplate,
    Skeletonitems,
} from '../../../appconfig/Settings';

//Services
import PageService from '../../../service/PageService';
import { Loader } from '../../../components/Loader/Loader';

export const ApplicantTrash = () => {
    document.title = "Applicant Trash | Venture Studio"

    //BreadCrumb
    const items = [
        {
            label: 'InputText',
            template: () => <span className="p-breadcrumb-item active">Applicants</span>
        },
        {
            label: 'InputText',
            template: () => <span className="p-breadcrumb-item active">Applicants Trash</span>
        }
    ];
    const home = { icon: 'pi pi-home', url: '/dashboard' }

    //Navigate Another Route
    const navigate = useNavigate();

    //Set Toast/ Filter Properties
    const [globalFilter, setGlobalFilter] = useState<any>(null);
    const toast = useRef<any>(null);

    // Page service
    const pageService = new PageService();

    //Loading/Page Loading
    const [pageLoad, setPageLoad] = useState(false);
    const [applicantsList, setApplicantsList] = useState<any>([]);
    const [statusChangePageLoad, setStatusChangePageLoad] = useState(false);
    const [jobRoles, setJobRoles] = useState<any>([]);
    const [selectedJobRole, setSelectedJobRole] = useState<any>({ name: "All", code: "All" });
    const [restoreApplicantModal, setRestoreApplicantModal] = useState<boolean>(false);
    const [restoreApplicantLoader, setRestoreApplicantLoader] = useState<boolean>(false);
    const [restoreId, setRestoreId] = useState<any>(null);

    // use effect method
    useEffect(() => {
        getApplicantsDataFromAPI();
        getJobRolesFromAPI();
        window.scrollTo({ top: 0, behavior: 'smooth' });
    }, [selectedJobRole]);

    // Get job roles for dropdown
    const getJobRolesFromAPI = () => {
        // Api call
        pageService
            .getJobRolesListForDropdown()
            .then((response) => {
                // Get response
                if (response) {
                    const DataList = response;
                    if (DataList.length == 0) {
                        setJobRoles([]);
                    } else {
                        let tempJobRoleArr = [{ name: "All", code: "All" }];
                        DataList.map((item: any, index: number) => {
                            tempJobRoleArr.push(item);
                        });
                        setJobRoles(tempJobRoleArr);
                    }
                } else {
                    setJobRoles([]);
                }
            });
    };

    // Get Applicants Data from API
    const getApplicantsDataFromAPI = async () => {

        // Api call
        pageService
            .getApplicantTrashList(selectedJobRole.code)
            .then((response) => {
                // Get response
                if (response) {
                    const DataList = response;
                    if (DataList.length == 0) {
                        setApplicantsList([]);
                    } else {
                        setApplicantsList(DataList);
                    }
                    setPageLoad(true);
                } else {
                    setPageLoad(false);
                    setApplicantsList([]);
                }
            });
    };

    // left part of toolbar
    const leftToolbarTemplate = () => {
        return (
            <>
                <span className="block mt-2 md:mt-0 p-input-icon-left">
                    <i className="pi pi-search" />
                    <InputText
                        type="search"
                        onInput={(e) => setGlobalFilter(e.currentTarget.value)}
                        placeholder="Search..."
                    />
                </span>
            </>
        );
    };

    // right part of toolbar
    const rightToolbarTemplate = () => {
        return (
            <>
                <Dropdown
                    value={selectedJobRole}
                    onChange={(e) => setSelectedJobRole(e.value)}
                    options={jobRoles}
                    optionLabel="name"
                    placeholder="Job Role"
                ></Dropdown>
            </>
        );
    };

    // Column templates
    const dateFormatCreatedAtTemplate = (rowData: any, rowIndex: any) => {

        return (
            <>
                {rowData.created_at === rowData[rowIndex.field] && rowData.created_at !== null ? moment.utc(rowData.created_at).format('MMM DD, YYYY') : '-'}
            </>
        );
    };

    // Full name body template
    const fullNameTemplate = (rowData: any, rowIndex: any) => {
        return (
            <>
                <a className="tb-avatar-box" href={'/applicant/details/' + rowData.id + "/" + window.btoa("noteligible")} target="_blank">{rowData?.profile_img != null && rowData?.profile_img != "null" && rowData?.profile_img != "" ?
                    <Avatar className="tb-avatar-img" image={rowData?.profile_img} shape="circle" /> : <Avatar className='tb-avatar-img user-list-avatar' label={rowData?.full_name.charAt(0).toUpperCase()} shape="circle" />}<div className="tb-avatar-info"><div className="tb-avatar-name">{rowData?.full_name}</div><div className="tb-avatar-text">{rowData?.email}</div></div>
                </a>
            </>
        )
    };

    // Action template
    const actionTemplate = (rowData: any, rowIndex: any) => {
        return (
            <div className="tb-actions">
                <Button
                    type="button"
                    icon="pi pi-undo"
                    className='p-button-secondary'
                    tooltip='Restore Applicant'
                    tooltipOptions={{ position: "top" }}
                    onClick={() => openRestoreModal(rowData.id)}
                />
            </div>
        )
    };

    // Open restore modal
    const openRestoreModal = (id: any) => {
        setRestoreId(id);
        setRestoreApplicantModal(true);
    };

    // Close restore modal
    const closeRestoreModal = () => {
        setRestoreId(null);
        setRestoreApplicantLoader(false);
        setRestoreApplicantModal(false);
    };

    // Revert applicant
    const revertApplicantAPICall = () => {
        try {
            setStatusChangePageLoad(true);
            // request data
            let formData = new FormData();
            formData.append('id', restoreId);

            // call api
            pageService.restoreApplicant(formData).then((response) => {
                // Get response
                if (response) {
                    setRestoreId(null);
                    setRestoreApplicantLoader(false);
                    setRestoreApplicantModal(false);
                    toast.current?.show({
                        severity: 'success',
                        summary: 'Success',
                        detail: response.message,
                    });
                    setTimeout(() => {
                        setStatusChangePageLoad(false);
                        getApplicantsDataFromAPI();
                    }, 1000);
                } else {
                    setRestoreApplicantLoader(false);
                    setStatusChangePageLoad(false);
                    toast.current?.show({
                        severity: 'error',
                        summary: 'Error',
                        detail: 'Something went wrong, Please try again.',
                    });
                }
            });
        } catch (error: any) {
            setStatusChangePageLoad(false);
            toast.current?.show({
                severity: 'error',
                summary: 'Error',
                detail: error.response.data.error,
            });
        }
    };

    return (
        <>
            <Toast ref={toast} />
            <div className="page-header">
                <div className="page-leftheader">
                    <div className="page-header-info">
                        <div className="page-title"><Button icon="pi pi-arrow-left" className="link-prev-btn" onClick={() => navigate(-1)} /> Applicants Trash</div>
                        <div className="main-content-breadcrumb">
                            <BreadCrumb model={items} home={home} />
                        </div>
                    </div>
                </div>
                <div className="page-rightheader">
                    <div className="btn-icon-list">
                        <div className="page-header-search">
                            <Toolbar className="page-header-search-area" left={leftToolbarTemplate} right={rightToolbarTemplate}></Toolbar>
                        </div>
                    </div>
                </div>
            </div>
            <div className="grid crud-demo">
                <div className="col-12">
                    <div className="card">
                        <div className="card-body">
                            {/* Datatable Start */}
                            {pageLoad ? (
                                <>
                                    <DataTable
                                        className="datatable-responsive" stripedRows
                                        value={applicantsList}
                                        paginator={applicantsList.length > 0 && true}
                                        globalFilter={globalFilter}
                                        rows={defaultRowOptions}
                                        rowsPerPageOptions={defaultPageRowOptions}
                                        paginatorTemplate={paginatorLinks}
                                        currentPageReportTemplate={showingEntries}
                                        emptyMessage="No Applicants Found"
                                    >
                                        {ApplicantTrashColumns.map((col, i) => {
                                            if (col.field === 'created_at') {
                                                return (
                                                    <Column
                                                        key={col.field}
                                                        field={col.field}
                                                        header={col.header}
                                                        body={dateFormatCreatedAtTemplate}
                                                        filter
                                                        sortable
                                                    />
                                                );
                                            } else if (col.field === 'sr_no') {
                                                return (
                                                    <Column
                                                        key={col.field}
                                                        field={col.field}
                                                        header={col.header}
                                                        body={(_, { rowIndex }) => rowIndex + 1}
                                                    />
                                                );
                                            } else if (col.field === 'full_name') {
                                                return (
                                                    <Column
                                                        key={col.field}
                                                        field={col.field}
                                                        header={col.header}
                                                        body={fullNameTemplate}
                                                    />
                                                );
                                            } else if (col.field === 'action') {
                                                return (
                                                    <Column
                                                        key={col.field}
                                                        field={col.field}
                                                        header={col.header}
                                                        body={actionTemplate}
                                                    />
                                                );
                                            } else {
                                                return (
                                                    <Column
                                                        key={col.field}
                                                        field={col.field}
                                                        header={col.header}
                                                        sortable
                                                        filter
                                                    />
                                                );
                                            }
                                        })}
                                    </DataTable>
                                </>
                            ) : (
                                <>
                                    {/* Skeleton Data table */}
                                    <DataTable value={Skeletonitems}>
                                        {ApplicantTrashColumns.map((col, i) => (
                                            <Column
                                                key={col.field}
                                                field={col.field}
                                                header={col.header}
                                                body={SkeletonbodyTemplate}
                                            />
                                        ))}
                                    </DataTable>
                                </>
                            )}
                            {/* Datatable End */}
                        </div>
                    </div>
                </div>
            </div>

            {/* Revert File/Folder Dialog */}
            <Dialog
                visible={restoreApplicantModal}
                style={{ width: '450px' }}
                header={"Restore Applicant"}
                modal
                footer={
                    <>
                        <Button
                            label="Cancel"
                            icon="pi pi-times"
                            className="p-button-secondary"
                            onClick={closeRestoreModal}
                        />
                        <Button
                            label="Restore"
                            icon="pi pi-check"
                            className="p-button-success"
                            onClick={revertApplicantAPICall}
                            loading={restoreApplicantLoader}
                        />
                    </>
                }
                onHide={closeRestoreModal}
            >
                <div className="flex align-items-center justify-content-start">
                    <p>Do you really want to restore the aplicant?</p>
                </div>
            </Dialog>

            {/* Loader Start */}
            {
                statusChangePageLoad && <Loader />
            }
            {/* Loader End */}
        </>
    )
};