import { useEffect, useRef, useState } from 'react';
import { useNavigate, useParams, Link } from 'react-router-dom';
import SpeechRecognition, { useSpeechRecognition } from 'react-speech-recognition';

//Prime React Component Inbuilt
import { Button } from 'primereact/button';
import { BreadCrumb } from 'primereact/breadcrumb';
import { Toast } from 'primereact/toast';
import { Badge } from 'primereact/badge';
import { Dialog } from 'primereact/dialog';
import { Avatar } from 'primereact/avatar';
import { InputTextarea } from 'primereact/inputtextarea';
import { Rating } from 'primereact/rating';

//Services
import PageService from '../../../service/PageService';
import { Worker, Viewer } from '@react-pdf-viewer/core';
import { zoomPlugin } from '@react-pdf-viewer/zoom';
import { Loader } from '../../../components/Loader/Loader';
import { convertDatetimeFormat } from '../../../appconfig/AppHelper';
import moment from 'moment';
import { applicantNoteValidate } from '../../../config/Validate';
import { applicantRatingsTypes } from '../../../appconfig/Settings';

export const InterviewDetails = () => {
    document.title = "Interview Details | Venture Studio"

    //BreadCrumb
    const items = [
        {
            label: 'InputText',
            template: () => <Link className="p-breadcrumb-item" to="/interviews">Interviews</Link>
        },
        {
            label: 'InputText',
            template: () => <span className="p-breadcrumb-item active">Interview Details</span>
        }
    ];
    const home = { icon: 'pi pi-home', url: '/dashboard' }

    //Navigate Another Route
    const navigate = useNavigate();
    const toast = useRef<any>(null);
    const { applicant_id } = useParams<any>();

    const zoomPluginInstance = zoomPlugin();
    const pageService = new PageService();

    const [pageLoad, setPageLoad] = useState<boolean>(false);
    const [interviewDetail, setInterviewDetail] = useState<any>({});
    const [resultModal, setResultModal] = useState<boolean>(false);
    const [passLoader, setPassLoader] = useState<boolean>(false);
    const [failLoader, setFailLoader] = useState<boolean>(false);
    const [resultId, setResultId] = useState<any>(null);
    const [applicantNotes, setApplicantNotes] = useState<any>([]);
    const [note, setNote] = useState<any>("");
    const [isListening, setIsListening] = useState<boolean>(false);
    const [saveNoteLoader, setSaveNoteLoader] = useState<boolean>(false);
    const [userFeedbacks, setUserFeedbacks] = useState<any>([]);
    const [otherUserFeedbacks, setOtherUserFeedbacks] = useState<any>([]);
    const [errors, setErrors] = useState<any>({});
    
    const {
        transcript,
        listening,
        resetTranscript,
        browserSupportsSpeechRecognition
    } = useSpeechRecognition();

    // useEffect
    useEffect(() => {
        getInterviewDetailFromAPI();
        getApplicantNotesFromAPI();
        getApplicantFeedbacksFromAPI();
    }, []);

    useEffect(() => {
        setNote(note + transcript);
    }, [transcript]);

    // Get applicant feedbacks from api
    const getApplicantFeedbacksFromAPI = () => {
        // Api call
        pageService
            .getApplicantFeedbacks(applicant_id)
            .then((response) => {
                // Get response
                if (response) {
                    // Set interview ratings
                    if (response.user_feedback.length > 0) {
                        let tempRatingArr: any = [];
                        response.user_feedback.map((item: any, index: any) => {
                            let tempRatingObj: any = {};
                            tempRatingObj["id"] = item.id;
                            tempRatingObj["code"] = item.type;
                            tempRatingObj["rating"] = item.rating;
                            tempRatingArr.push(tempRatingObj);
                        });
                        setUserFeedbacks(tempRatingArr);
                    }

                    if (response.other_user_feedback.length > 0) {
                        const groupedRatings: any = response.other_user_feedback.reduce((acc: any, item: any) => {
                            // Check if the category already exists in accumulator
                            const group = acc.find((g: any) => g.interviewer_id === item.interviewer_id);

                            if (group) {
                                group.items.push(item);
                            } else {
                                acc.push({ interviewer_id: item.interviewer_id, interviewer_name: item.feedback_given_by.first_name + " " + item.feedback_given_by.last_name, items: [item] });
                            }

                            return acc;
                        }, []);
                        setOtherUserFeedbacks(groupedRatings);
                    }
                }
            });
    };

    // Get interview detail from api
    const getInterviewDetailFromAPI = () => {
        setPageLoad(true);
        // Api call
        pageService
            .getInterviewDetail(applicant_id, localStorage.getItem("id"))
            .then((response) => {
                // Get response
                if (response) {
                    setInterviewDetail(response);
                    setPageLoad(false);
                } else {
                    setInterviewDetail({});
                    setPageLoad(false);
                }
            });
    };

    // Get applicant notes from api
    const getApplicantNotesFromAPI = () => {
        setPageLoad(true);
        // Api call
        pageService
            .getApplicantNotes(applicant_id)
            .then((response) => {
                // Get response
                if (response) {
                    setApplicantNotes(response);
                    setPageLoad(false);
                } else {
                    setApplicantNotes([]);
                    setPageLoad(false);
                }
            });
    };

    // Open result modal
    const openResultModal = (id: any) => {
        setResultId(id);
        setResultModal(true);
    };

    // Close result modal
    const closeResultModal = () => {
        setResultId(null);
        setPassLoader(false);
        setFailLoader(false);
        setResultModal(false);
    };

    // Give result api call
    const giveInterviewResultApiCall = (status: any) => {
        try {
            if (status === "Failed") {
                setFailLoader(true);
            } else if (status === "Passed") {
                setPassLoader(true);
            }

            // request data
            let formData: any = new FormData();
            formData.append('id', resultId);
            formData.append('applicant_id', applicant_id);
            formData.append('status', status);

            // call api
            pageService.giveInterviewResult(formData).then((response) => {
                // Get response
                if (response) {
                    setPassLoader(false);
                    setFailLoader(false);
                    setResultModal(false);
                    toast.current?.show({
                        severity: 'success',
                        summary: 'Success',
                        detail: response.message,
                    });
                    setTimeout(() => {
                        getInterviewDetailFromAPI();
                    }, 1000);
                } else {
                    setPassLoader(false);
                    setFailLoader(false);
                    toast.current?.show({
                        severity: 'error',
                        summary: 'Error',
                        detail: 'Something went wrong, Please try again.',
                    });
                }
            });
        } catch (error: any) {
            setPassLoader(false);
            setFailLoader(false);
            toast.current?.show({
                severity: 'error',
                summary: 'Error',
                detail: error.response.data.error,
            });
        }
    };

    // Start mic for note
    const startListening = () => {
        setIsListening(true);
        SpeechRecognition.startListening();
    };

    // Stop mic for note
    const stopListening = () => {
        setIsListening(false);
        SpeechRecognition.stopListening();
    };

    // On save note
    const onSaveNote = () => {
        const { errors, isError } = applicantNoteValidate(note);
        setErrors(errors);
        try {
            if (!isError) {
                setSaveNoteLoader(true);
                let formData: any = new FormData();
                formData.append("note", note);
                formData.append("applicant_id", applicant_id);
                formData.append("user_id", localStorage.getItem("id"));

                // call api
                pageService.addUpdateInterviewNotes(formData).then((response) => {
                    // Get response
                    if (response) {
                        setSaveNoteLoader(false);
                        setNote("");
                        toast.current?.show({
                            severity: 'success',
                            summary: 'Success',
                            detail: response.message,
                        });
                        getApplicantNotesFromAPI();
                    } else {
                        setSaveNoteLoader(false);
                        toast.current?.show({
                            severity: 'error',
                            summary: 'Error',
                            detail: 'Something went wrong, Please try again.',
                        });
                    }
                });
            }
        } catch (error: any) {
            setSaveNoteLoader(false);
            toast.current?.show({
                severity: 'error',
                summary: 'Error',
                detail: error.response.data.error,
            });
        }
    };

    // On rating change
    const onRatingChange = (val: any, name: any, item: any) => {
        setPageLoad(true);

        let formData: any = new FormData();
        if (!window.cn(item) && !window.cn(item.id)) {
            formData.append("id", item.id);
        }
        formData.append("applicant_id", applicant_id);
        formData.append("type", name);
        formData.append("rating", val);
        formData.append("interviewer_id", localStorage.getItem("id"));

        // call api
        pageService.giveApplicantRatings(formData).then((response) => {
            // Get response
            if (response) {
                setPageLoad(false);
                toast.current?.show({
                    severity: 'success',
                    summary: 'Success',
                    detail: response.message,
                });
                getApplicantFeedbacksFromAPI();
            } else {
                setPageLoad(false);
                toast.current?.show({
                    severity: 'error',
                    summary: 'Error',
                    detail: 'Something went wrong, Please try again.',
                });
            }
        });
    };

    // Overall ratings template
    const overAllRatingTemplate = (allRatings: any) => {
        const totalRating = allRatings.reduce((acc: any, item: any) => acc + Number(item.rating), 0);
        const averageRating = totalRating / 5;
        return (
            <div className="col-12 md:col-2">
                <p><b>Overall</b></p>
                <Rating value={Number(averageRating.toFixed(0))} cancel={false} disabled />
            </div>
        )
    }

    return (
        <>
            <Toast ref={toast} />
            <div className="page-header">
                <div className="page-leftheader">
                    <div className="page-header-info">
                        <div className="page-title"><Button icon="pi pi-arrow-left" className="link-prev-btn" onClick={() => navigate(-1)} /> Interview Details</div>
                        <div className="main-content-breadcrumb">
                            <BreadCrumb model={items} home={home} />
                        </div>
                    </div>
                </div>
            </div>
            {/* Applicant Details */}
            <div className="card">
                <div className="card-body">
                    <div className="field col">
                        <div className="grid">
                            <div className="field col-4 flex flex-column">
                                <div className="viewcard-box">
                                    <div className="viewcard-title">Applicant Name</div>
                                    <div className="viewcard-text">{!window.cn(interviewDetail) ? interviewDetail?.applicant?.first_name + " " + interviewDetail?.applicant?.last_name : ""}</div>
                                </div>
                            </div>
                            <div className="field col-4 flex flex-column">
                                <div className="viewcard-box">
                                    <div className="viewcard-title">Email</div>
                                    <div className="viewcard-text">{!window.cn(interviewDetail) ? interviewDetail?.applicant?.email : ""}</div>
                                </div>
                            </div>
                            <div className="field col-4 flex flex-column">
                                <div className="viewcard-box">
                                    <div className="viewcard-title">Mobile Number</div>
                                    <div className="viewcard-text">{!window.cn(interviewDetail) ? interviewDetail?.applicant?.mobile : ""}</div>
                                </div>
                            </div>
                            <div className="field col-4 flex flex-column">
                                <div className="viewcard-box">
                                    <div className="viewcard-title">Job Title</div>
                                    <div className="viewcard-text">{!window.cn(interviewDetail) ? interviewDetail?.applicant?.job_post?.job_title : ""}</div>
                                </div>
                            </div>
                            <div className="field col-4 flex flex-column">
                                <div className="viewcard-box">
                                    <div className="viewcard-title">Job Role</div>
                                    <div className="viewcard-text">{!window.cn(interviewDetail) ? interviewDetail?.applicant?.interviews[0].interview_stage?.job_role_detail?.name : ""}</div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* Scheduled Interviews */}
            <div className="grid">
                <div className="col-12 md:col-6">
                    <div className="card">
                        <div className="card-header">
                            <div className="card-title-box">
                                <h3 className="card-title">Scheduled Interviews</h3>
                            </div>
                        </div>
                        <div className='card-body'>
                            {
                                interviewDetail?.applicant?.interviews.map((item: any, index: any) => {
                                    return (
                                        <>
                                            <div className='flex' style={{ justifyContent: "space-between", alignItems: "baseline" }}>
                                                <p><b>{item?.interview_stage?.interview_stages_master?.name}</b></p>
                                                <p>{<Badge severity={item.status === "Pending" ? "warning" : item.status === "Failed" ? "danger" : "success"} value={item.status} />}</p>
                                            </div>
                                            <div className='flex' style={{ justifyContent: "space-between", alignItems: "baseline" }}>
                                                <p>{convertDatetimeFormat(item.scheduled_at)}</p>
                                                {
                                                    item.status === "Pending" ?
                                                        <Button
                                                            className='p-button-success p-button-outlined ml-2'
                                                            label='Give Result'
                                                            onClick={() => openResultModal(item.id)}
                                                        />
                                                        :
                                                        <></>
                                                }
                                            </div>
                                            {interviewDetail?.applicant?.interviews.length > 1 ? <hr /> : ""}
                                        </>
                                    )
                                })
                            }
                        </div>
                    </div>
                </div>
                {/* Notes */}
                <div className="col-12 md:col-6">
                    <div className="card">
                        <div className="card-header">
                            <div className="card-title-box">
                                <h3 className="card-title">Interview Notes</h3>
                            </div>
                        </div>
                        <div className='card-body'>
                            {
                                applicantNotes.length === 0 ?
                                    <>
                                        <div className='flex' style={{ justifyContent: "center" }}>
                                            <img src="/assets/images/no-notes.jpg" alt="no-notes" width={200} />
                                        </div>
                                        <div className='flex mt-2' style={{ justifyContent: "center" }}>
                                            <p><b>No Notes Found</b></p>
                                        </div>
                                    </>
                                    :
                                    <div className="card-body-inner border-top v-scroll">
                                        <div className="comments-note grid">
                                            <div className="field col-12">
                                                <ul className="list-none m-0 p-0">
                                                    {
                                                        applicantNotes.map((item: any, index: any) => {
                                                            return (
                                                                <>
                                                                    {
                                                                        <li>
                                                                            <a className="flex surface-border mb-3 p-3 border-1 surface-border border-round hover:surface-hover transition-colors transition-duration-150">
                                                                                {item?.added_by?.profile_img != null && item?.added_by?.profile_img != "null" && item?.added_by?.profile_img != "" ?
                                                                                    <Avatar image={item?.added_by?.profile_img} shape="circle" />
                                                                                    :
                                                                                    <Avatar label={item?.added_by?.first_name.charAt(0).toUpperCase()} shape="circle" />
                                                                                }
                                                                                <div className="ml-3 flex-1">
                                                                                    <p className="font-semibold m-0">{item?.note}</p>
                                                                                    <div className="notes-info">
                                                                                        <div className="notes-info-list"><i className="ti ti-clock"></i>{moment(item?.created_at).format("MMM DD, YYYY")} by {item?.added_by?.first_name} {item?.added_by?.last_name}</div>
                                                                                    </div>
                                                                                </div>
                                                                            </a>
                                                                        </li>
                                                                    }
                                                                </>
                                                            )
                                                        })
                                                    }
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                            }
                            <div className="p-fluid formgrid grid">
                                <div className="field col-12">
                                    <label htmlFor="name">Enter Note <span className="required">*</span></label>
                                    <InputTextarea
                                        value={note}
                                        onChange={(e) => setNote(e.target.value)}
                                        rows={5}
                                        cols={45}
                                        placeholder='Enter Note'
                                        className={errors['note'] && 'p-invalid'}
                                    />
                                    {errors['note'] !== undefined ? <small className="p-invalid-txt">{errors['note']}</small> : <></>}
                                </div>
                                <div className="field col-12">
                                    <div className="text-right">
                                        {
                                            isListening ?
                                                <Button
                                                    className='p-button-danger'
                                                    onClick={stopListening}
                                                    icon="ti ti-microphone-off"
                                                />
                                                :
                                                <Button
                                                    className='p-button-secondary'
                                                    onClick={startListening}
                                                    icon="ti ti-microphone"
                                                />
                                        }
                                        <Button
                                            className='ml-2'
                                            onClick={onSaveNote}
                                            icon="ti ti-check"
                                            loading={saveNoteLoader}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* Ratings */}
            <div className='grid'>
                <div className="col-12 md:col-12">
                    <div className="card">
                        <div className="card-header">
                            <div className="card-title-box">
                                <h3 className="card-title">Ratings</h3>
                            </div>
                            
                        </div>
                        <div className='card-body'>
                            {
                                otherUserFeedbacks.map((main_item: any, main_index: any) => {
                                    return (
                                        <>
                                            <p className='ml-2'><b>Added By {main_item.interviewer_name}</b></p>
                                            <div className="grid col-12" key={main_index}>
                                                {applicantRatingsTypes.map((item: any, index: any) => {
                                                    let currRating = main_item.items.find(
                                                        (rating_item: any) => rating_item.type === item.code
                                                    );
                                                    return (
                                                        <div className="col-12 md:col-2" key={index}>
                                                            <p><b>{item.name}</b></p>
                                                            <Rating
                                                                value={!window.cn(currRating) && currRating !== undefined ? Number(currRating?.rating) : 0}
                                                                cancel={false}
                                                                disabled={true}
                                                            />
                                                        </div>
                                                    );
                                                })}
                                                {overAllRatingTemplate(main_item.items)}
                                            </div>
                                            <hr/>
                                        </>
                                    );
                                })
                            }
                            <p className='ml-2'><b>Added By You</b></p>
                            <div className="grid col-12">
                                {
                                    applicantRatingsTypes.map((item: any, index: any) => {
                                        let currRating = userFeedbacks.find((rating_item: any) => (rating_item.code === item.code));
                                        return (
                                            <div className="col-12 md:col-2" key={index}>
                                                <p><b>{item.name}</b></p>
                                                <Rating value={!window.cn(currRating) && currRating !== undefined ? Number(currRating?.rating) : 0} onChange={(e) => onRatingChange(e.value, item.code, currRating)} cancel={false} />
                                            </div>
                                        )
                                    })
                                }
                                {overAllRatingTemplate(userFeedbacks)}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* Resume */}
            {
                interviewDetail?.applicant?.resume !== undefined ?
                    <div className="card">
                        <div className="card-body">
                            <Worker workerUrl={`https://unpkg.com/pdfjs-dist@2.16.105/build/pdf.worker.min.js`}>
                                <Viewer
                                    fileUrl={interviewDetail?.applicant?.resume}
                                    plugins={[zoomPluginInstance]}
                                    enableSmoothScroll={true}
                                />
                            </Worker>
                        </div>
                    </div>
                    :
                    <></>
            }

            <Dialog
                visible={resultModal}
                style={{ width: '450px' }}
                className="p-fluid"
                header="Give Interview Result"
                modal
                footer={
                    <>
                        <Button
                            className='p-button-danger ml-2'
                            label='Failed'
                            onClick={() => giveInterviewResultApiCall("Failed")}
                            loading={failLoader}
                        />
                        <Button
                            className='p-button-success ml-2'
                            label='Passed'
                            onClick={() => giveInterviewResultApiCall("Passed")}
                            loading={passLoader}
                        />
                    </>
                }
                onHide={closeResultModal}
            >
                <h6>Please select is user has passed the interview or failed.</h6>
            </Dialog>

            {
                pageLoad && <Loader />
            }
        </>
    )
};
