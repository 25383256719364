import { useEffect, useRef, useState } from 'react';
import { useNavigate, useParams, Link } from 'react-router-dom';
import SpeechRecognition, { useSpeechRecognition } from 'react-speech-recognition';

//Prime React Component Inbuilt
import { Button } from 'primereact/button';
import { BreadCrumb } from 'primereact/breadcrumb';
import { Toast } from 'primereact/toast';
import { Badge } from 'primereact/badge';
import { Timeline } from 'primereact/timeline';
import { Dropdown } from 'primereact/dropdown';
import { Dialog } from 'primereact/dialog';
import { Calendar } from 'primereact/calendar';
import { InputTextarea } from 'primereact/inputtextarea';
import { Avatar } from 'primereact/avatar';
import { Rating } from 'primereact/rating';

//Services
import PageService from '../../../service/PageService';

import {
    applicantRatingsTypes,
    applicantStatusChange,
} from '../../../appconfig/Settings';

import { Worker, Viewer } from '@react-pdf-viewer/core';
import { zoomPlugin } from '@react-pdf-viewer/zoom';
import { Loader } from '../../../components/Loader/Loader';
import { ScheduleInterviewValidate, applicantNoteValidate } from '../../../config/Validate';
import moment from 'moment';
import { convertDatetimeFormat } from '../../../appconfig/AppHelper';

export const CareerApplicantsDetail = () => {
    document.title = "Applicant Details | Venture Studio"

    //BreadCrumb
    const items = [
        {
            label: 'InputText',
            template: () => <Link className="p-breadcrumb-item" to="/applicants">Applicant List</Link>
        },
        {
            label: 'InputText',
            template: () => <span className="p-breadcrumb-item active">Applicant Details</span>
        }
    ];
    const home = { icon: 'pi pi-home', url: '/dashboard' }

    //Navigate Another Route
    const navigate = useNavigate();
    const toast = useRef<any>(null);
    const { id, eligiblity } = useParams<any>();

    const zoomPluginInstance = zoomPlugin();
    const pageService = new PageService();

    const [pageLoad, setPageLoad] = useState<boolean>(false);
    const [applicantData, setApplicantData] = useState<any>({});
    const [statusChangePageLoad, setStatusChangePageLoad] = useState(false);
    const [interviewStagesData, setInterviewStagesData] = useState<any>([]);
    const [interviewers, setInterviewers] = useState<any>([]);
    const [scheduleInterviewModal, setScheduleInterviewModal] = useState<boolean>(false);
    const [scheduleInterviewLoader, setScheduleInterviewLoader] = useState<boolean>(false);
    const [selectedRoundDetails, setSelectedRoundDetails] = useState<any>({});
    const [scheduleInterviewData, setScheduleInterviewData] = useState<any>({});
    const [applicantNotes, setApplicantNotes] = useState<any>([]);
    const [note, setNote] = useState<any>("");
    const [isListening, setIsListening] = useState<boolean>(false);
    const [saveNoteLoader, setSaveNoteLoader] = useState<boolean>(false);
    const [userFeedbacks, setUserFeedbacks] = useState<any>([]);
    const [otherUserFeedbacks, setOtherUserFeedbacks] = useState<any>([]);
    const [errors, setErrors] = useState<any>({});

    const {
        transcript,
        listening,
        resetTranscript,
        browserSupportsSpeechRecognition
    } = useSpeechRecognition();

    useEffect(() => {
        getApplicantNotesFromAPI();
        getSingleApplicantDataFromAPI();
        getInterviewersListFromAPI();
        getApplicantFeedbacksFromAPI();
    }, []);

    // Get applicant feedbacks from api
    const getApplicantFeedbacksFromAPI = () => {
        // Api call
        pageService
            .getApplicantFeedbacks(id)
            .then((response) => {
                // Get response
                if (response) {
                    // Set interview ratings
                    if (response.user_feedback.length > 0) {
                        let tempRatingArr: any = [];
                        response.user_feedback.map((item: any, index: any) => {
                            let tempRatingObj: any = {};
                            tempRatingObj["id"] = item.id;
                            tempRatingObj["code"] = item.type;
                            tempRatingObj["rating"] = item.rating;
                            tempRatingArr.push(tempRatingObj);
                        });
                        setUserFeedbacks(tempRatingArr);
                    }

                    if (response.other_user_feedback.length > 0) {
                        const groupedRatings: any = response.other_user_feedback.reduce((acc: any, item: any) => {
                            // Check if the interviewer_id already exists in accumulator
                            const group = acc.find((g: any) => g.interviewer_id === item.interviewer_id);

                            if (group) {
                                group.items.push(item);
                            } else {
                                acc.push({ interviewer_id: item.interviewer_id, interviewer_name: item.feedback_given_by.first_name + " " + item.feedback_given_by.last_name, items: [item] });
                            }

                            return acc;
                        }, []);
                        setOtherUserFeedbacks(groupedRatings);
                    }
                }
            });
    };

    // Get applicant notes from api
    const getApplicantNotesFromAPI = () => {
        setPageLoad(true);
        // Api call
        pageService
            .getApplicantNotes(id)
            .then((response) => {
                // Get response
                if (response) {
                    setApplicantNotes(response);
                    setPageLoad(false);
                } else {
                    setApplicantNotes([]);
                    setPageLoad(false);
                }
            });
    };

    // Get interviewers list
    const getInterviewersListFromAPI = () => {
        // Api call
        pageService
            .getInterviewersList()
            .then((response) => {
                // Get response
                if (response) {
                    setInterviewers(response);
                    setPageLoad(false);
                } else {
                    setInterviewers([]);
                    setPageLoad(false);
                }
            });
    };

    // Get single applicant data
    const getSingleApplicantDataFromAPI = () => {
        setPageLoad(true);
        // Api call
        pageService
            .getSingleApplicantDetails(id)
            .then((response) => {
                // Get response
                if (response) {
                    setApplicantData(response);

                    // Set interview stages list
                    if (!window.cn(response?.job_post?.job_role?.interview_stages) && response?.job_post?.job_role?.interview_stages.length > 0){
                        // Make interview timeline array
                        let interviewTimelineArr: any = [];
                        response?.job_post?.job_role?.interview_stages.map((item: any, index: any) => {
                            let interviewTimelineObj: any = {};

                            // Set timeline items in object
                            interviewTimelineObj["stage_id"] = item.id;
                            interviewTimelineObj["stage_name"] = item.interview_stages_master.name;
                            interviewTimelineObj["stage_master_id"] = item.interview_stage_master_id;
                            interviewTimelineObj["icon"] = "ti ti-number-" + Number(index + 1);
                            // Check if interview scheduled for this stage or not
                            let scheduledStageInterview = response?.interviews.filter((schedule_item: any) =>{
                                if (schedule_item.applicant_id == id && schedule_item.interview_stage_id == item.id) {
                                    return schedule_item;
                                }
                            });
                            if(scheduledStageInterview.length > 0){
                                // Check for status and set color according to status
                                interviewTimelineObj["status"] = scheduledStageInterview[0]["status"];
                                if(scheduledStageInterview[0]["status"] === "Pending"){
                                    interviewTimelineObj["color"] = "#FF9800";
                                } else if(scheduledStageInterview[0]["status"] === "Failed"){
                                    interviewTimelineObj["color"] = "#D10000";
                                } else {
                                    interviewTimelineObj["color"] = "#00C000";
                                }

                                interviewTimelineObj["interviewer"] = scheduledStageInterview[0]["interviewer"];
                                interviewTimelineObj["date"] = scheduledStageInterview[0]["scheduled_at"];
                                interviewTimelineObj["result"] = scheduledStageInterview[0]["result"];
                                interviewTimelineObj["is_scheduled"] = true;
                            }else{
                                interviewTimelineObj["status"] = "Not Scheduled";
                                interviewTimelineObj["color"] = "#FF9800";
                                interviewTimelineObj["interviewer"] = {};
                                interviewTimelineObj["date"] = "";
                                interviewTimelineObj["result"] = ""
                                interviewTimelineObj["is_scheduled"] = false;
                            }

                            // Push timeline object in array
                            interviewTimelineArr.push(interviewTimelineObj);
                        });
                        setInterviewStagesData(interviewTimelineArr);
                    }

                    setPageLoad(false);
                } else {
                    setApplicantData({});
                    setPageLoad(false);
                }
            });
    };

    // on applicant status change
    const onApplicantStatusChange = (e: any, applicantId: any) => {
        try {
            setStatusChangePageLoad(true);
            // request data
            let formData = new FormData();
            formData.append('id', applicantId);
            formData.append('status', e.value.code);

            // call api
            pageService.applicantStatusChange(formData).then((response) => {
                // Get response
                if (response) {
                    toast.current?.show({
                        severity: 'success',
                        summary: 'Success',
                        detail: response.message,
                    });
                    setTimeout(() => {
                        setStatusChangePageLoad(false);
                        getSingleApplicantDataFromAPI();
                    }, 1000);
                } else {
                    setStatusChangePageLoad(false);
                    toast.current?.show({
                        severity: 'error',
                        summary: 'Error',
                        detail: 'Something went wrong, Please try again.',
                    });
                }
            });
        } catch (error: any) {
            setStatusChangePageLoad(false);
            toast.current?.show({
                severity: 'error',
                summary: 'Error',
                detail: error.response.data.error,
            });
        }
    };

    // Timeline marker
    const customizedMarker = (item: any) => {
        return (
            <span className="flex w-2rem h-2rem align-items-center justify-content-center text-white border-circle z-1 shadow-1" style={{ backgroundColor: item.color }}>
                <i className={item.icon}></i>
            </span>
        );
    };

    // Timeline content
    const customizedContent = (item: any) => {
        return (
            <>
                <div className='card' style={{padding: "10px"}}>
                    <div className='flex' style={{ justifyContent: "space-between", alignItems: "baseline" }}>
                        <h6><b>{item.stage_name}</b></h6>
                        {
                            item?.is_scheduled === true ?
                                <>
                                    {<Badge severity={item?.status === "Pending" ? "warning" : item?.status === "Failed" ? "danger" : "success"} value={ item?.status === "Pending" ? "Pending" : item?.status === "Failed" ? "Failed" : "Passed"}></Badge>}
                                </>
                            :
                                <Button
                                    icon='pi pi-clock'
                                    tooltip='Schedule Interview'
                                    tooltipOptions={{ position: "top" }}
                                    className='p-button-rounded p-button-outlined'
                                    onClick={() => openScheduleInterviewModal(item)}
                                />
                        }
                    </div>
                    {
                        item?.is_scheduled === true ?
                            <div className='flex' style={{ justifyContent: "space-between", alignItems: "baseline" }}>
                                <p>{item?.interviewer?.first_name} {item?.interviewer?.last_name}</p>
                                <p>{convertDatetimeFormat(item?.date)}</p>
                            </div>
                        :
                            <></>
                    }
                </div>
            </>
        );
    };

    // Open schedule interview modal
    const openScheduleInterviewModal = (item: any) => {
        setSelectedRoundDetails(item);
        setScheduleInterviewModal(true);
    };

    // Close schedule interview modal
    const closeScheduleInterviewModal = () => {
        setErrors({});
        setSelectedRoundDetails({});
        setScheduleInterviewData({});
        setScheduleInterviewLoader(false);
        setScheduleInterviewModal(false);
    };

    // On input change
    const onInputChange = (e: any, name: string) => {
        let val: any;
        if (name === "interviewer_id"){
            val = e.value;
        }else{
            val = (e.target && e.target.value) || '';
        }
        setScheduleInterviewData({ ...scheduleInterviewData, [name]: val });
    }

    // On submit schedule interview
    const onSubmitScheduleInterview = () => {
        const { errors, isError } = ScheduleInterviewValidate(scheduleInterviewData);
        setErrors(errors);
        if (!isError) {
            setScheduleInterviewLoader(true);

            let formData: any = new FormData();
            formData.append("applicant_id", id);
            formData.append("interviewer_id", scheduleInterviewData.interviewer_id.code);
            formData.append("interview_stage_id", selectedRoundDetails.stage_id);

            // Convert selected datetime to my sql format from 12 hour selection to 24 hour
            const formattedDateTime = moment(scheduleInterviewData.scheduled_at, "MM/DD/YYYY hh:mm A").format("YYYY-MM-DD HH:mm:ss");
            formData.append("scheduled_at", formattedDateTime);

            // call api
            pageService.scheduleInterview(formData).then((response) => {
                // Get response
                if (response) {
                    setScheduleInterviewLoader(false);
                    setScheduleInterviewModal(false);
                    setScheduleInterviewData({});
                    setSelectedRoundDetails({});
                    getSingleApplicantDataFromAPI();
                    toast.current?.show({
                        severity: 'success',
                        summary: 'Success',
                        detail: response.message,
                    });
                } else {
                    setScheduleInterviewLoader(false);
                    setScheduleInterviewModal(true);
                    toast.current?.show({
                        severity: 'error',
                        summary: 'Error',
                        detail: 'Something went wrong, Please try again.',
                    });
                }
            }).catch(error => {
                setScheduleInterviewLoader(false);
                setScheduleInterviewModal(true);
                toast.current?.show({
                    severity: 'error',
                    summary: 'Message',
                    detail: error.response.data.error,
                });
            });
        }
    };

    // Start mic for note
    const startListening = () => {
        setIsListening(true);
        SpeechRecognition.startListening();
    };

    // Stop mic for note
    const stopListening = () => {
        setIsListening(false);
        SpeechRecognition.stopListening();
    };

    // On save note
    const onSaveNote = () => {
        const { errors, isError } = applicantNoteValidate(note);
        setErrors(errors);
        try {
            if (!isError) {
                setSaveNoteLoader(true);
                let formData: any = new FormData();
                formData.append("note", note);
                formData.append("applicant_id", id);
                formData.append("user_id", localStorage.getItem("id"));

                // call api
                pageService.addUpdateInterviewNotes(formData).then((response) => {
                    // Get response
                    if (response) {
                        setSaveNoteLoader(false);
                        setNote("");
                        toast.current?.show({
                            severity: 'success',
                            summary: 'Success',
                            detail: response.message,
                        });
                        getApplicantNotesFromAPI();
                    } else {
                        setSaveNoteLoader(false);
                        toast.current?.show({
                            severity: 'error',
                            summary: 'Error',
                            detail: 'Something went wrong, Please try again.',
                        });
                    }
                });
            }
        } catch (error: any) {
            setSaveNoteLoader(false);
            toast.current?.show({
                severity: 'error',
                summary: 'Error',
                detail: error.response.data.error,
            });
        }
    };

    // On rating change
    const onRatingChange = (val: any, name: any, item: any) => {
        setPageLoad(true);

        let formData: any = new FormData();
        if(!window.cn(item) && !window.cn(item.id)){
            formData.append("id", item.id);
        }
        formData.append("applicant_id", id);
        formData.append("type", name);
        formData.append("rating", val);
        formData.append("interviewer_id", localStorage.getItem("id"));

        // call api
        pageService.giveApplicantRatings(formData).then((response) => {
            // Get response
            if (response) {
                setPageLoad(false);
                toast.current?.show({
                    severity: 'success',
                    summary: 'Success',
                    detail: response.message,
                });
                getApplicantFeedbacksFromAPI();
            } else {
                setPageLoad(false);
                toast.current?.show({
                    severity: 'error',
                    summary: 'Error',
                    detail: 'Something went wrong, Please try again.',
                });
            }
        });
    };

    // Overall ratings template
    const overAllRatingTemplate = (allRatings: any) => {
        const totalRating = allRatings.reduce((acc: any, item: any) => acc + Number(item.rating), 0);
        const averageRating = totalRating / 5;
        return (
            <div className="col-12 md:col-2">
                <p><b>Overall</b></p>
                <Rating value={Number(averageRating.toFixed(0))} cancel={false} disabled />
            </div>
        )
    }

    return (
        <>
            <Toast ref={toast} />
            <div className="page-header">
                <div className="page-leftheader">
                    <div className="page-header-info">
                        <div className="page-title">Applicant Details</div>
                        <div className="main-content-breadcrumb">
                            <BreadCrumb model={items} home={home} />
                        </div>
                    </div>
                </div>
                <div className="page-rightheader">
                    <div className="btn-icon-list">
                        <div className="page-header-search">
                            <div className="p-toolbar page-header-search-area">
                                <Dropdown
                                    value={{ name: applicantData?.status, code: applicantData?.status }}
                                    onChange={(e) => onApplicantStatusChange(e, id)}
                                    options={applicantStatusChange}
                                    optionLabel="name"
                                ></Dropdown>
                            </div>
                        </div>
                        {
                            !window.cn(applicantData) && applicantData?.job_post?.give_task == "Yes" ?
                                <>
                                    <Button
                                        label='Task File'
                                        className="p-button-info"
                                        onClick={() => window.open(applicantData?.task_file, "_blank")}
                                        disabled={applicantData?.task_file !== null && applicantData?.task_file !== "null" && applicantData?.task_file !== "" ? false : true}
                                    />
                                </>
                                :
                                <></>
                        }
                    </div>
                </div>
            </div>
            {/* Applicant Details */}
            <div className="card">
                <div className="card-body">
                    <div className="field col">
                        <div className="grid">
                            <div className="field col-4 flex flex-column">
                                <div className="viewcard-box">
                                    <div className="viewcard-title">Applicant Name</div>
                                    <div className="viewcard-text">{!window.cn(applicantData) ? applicantData?.first_name + " " + applicantData?.last_name : ""}</div>
                                </div>
                            </div>
                            <div className="field col-4 flex flex-column">
                                <div className="viewcard-box">
                                    <div className="viewcard-title">Email</div>
                                    <div className="viewcard-text">{!window.cn(applicantData) ? applicantData?.email : ""}</div>
                                </div>
                            </div>
                            <div className="field col-4 flex flex-column">
                                <div className="viewcard-box">
                                    <div className="viewcard-title">Mobile Number</div>
                                    <div className="viewcard-text">{!window.cn(applicantData) ? applicantData?.mobile : ""}</div>
                                </div>
                            </div>
                            <div className="field col-4 flex flex-column">
                                <div className="viewcard-box">
                                    <div className="viewcard-title">Job Title</div>
                                    <div className="viewcard-text">{!window.cn(applicantData) ? applicantData?.job_post?.job_title : ""}</div>
                                </div>
                            </div>
                            <div className="field col-4 flex flex-column">
                                <div className="viewcard-box">
                                    <div className="viewcard-title">Company Name</div>
                                    <div className="viewcard-text">{!window.cn(applicantData) ? applicantData?.company?.company_name : ""}</div>
                                </div>
                            </div>
                            <div className="field col-4 flex flex-column">
                                <div className="viewcard-box">
                                    <div className="viewcard-title">Job Role</div>
                                    <div className="viewcard-text">{!window.cn(applicantData) ? applicantData?.job_post?.job_role?.name : ""}</div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            
            {
                window.atob(eligiblity ?? "") === "eligible" ?
                    <>
                        {/* Scheduled Interviews */}
                        <div className="grid">
                            <div className="col-12 md:col-6">
                                <div className="card">
                                    <div className="card-header">
                                        <div className="card-title-box">
                                            <h3 className="card-title">Scheduled Interviews</h3>
                                        </div>
                                        <div className='flex'>
                                            {
                                                applicantData.interview_status === "not_scheduled" ?
                                                    <Badge className='ml-2' severity='info' value='Not Scheduled'></Badge> :
                                                    applicantData.interview_status === "in_progress" ?
                                                        <Badge severity='warning' value='In Progress'></Badge> :
                                                        applicantData.interview_status === "hired" ?
                                                            <Badge severity='success' value='Hired'></Badge> :
                                                            applicantData.interview_status === "rejected" ?
                                                                <Badge severity='danger' value='Rejected'></Badge> :
                                                                applicantData.interview_status === "failed" ?
                                                                    <Badge severity='danger' value='Failed'></Badge>
                                                                    :
                                                                    <Badge severity='info' value='On Hold'></Badge>
                                            }
                                        </div>
                                    </div>
                                    <div className='card-body'>
                                        <Timeline
                                            value={interviewStagesData}
                                            align='alternate'
                                            marker={customizedMarker}
                                            content={customizedContent}
                                        />
                                    </div>
                                </div>
                            </div>
                            {/* Notes */}
                            <div className="col-12 md:col-6">
                                <div className="card">
                                    <div className="card-header">
                                        <div className="card-title-box">
                                            <h3 className="card-title">Interview Notes</h3>
                                        </div>
                                    </div>
                                    <div className='card-body'>
                                        {
                                            applicantNotes.length === 0 ?
                                                <>
                                                    <div className='flex' style={{ justifyContent: "center" }}>
                                                        <img src="/assets/images/no-notes.jpg" alt="no-notes" width={200} />
                                                    </div>
                                                    <div className='flex mt-2' style={{ justifyContent: "center" }}>
                                                        <p><b>No Notes Found</b></p>
                                                    </div>
                                                </>
                                                :
                                                <div className="card-body-inner border-top v-scroll">
                                                    <div className="comments-note grid">
                                                        <div className="field col-12">
                                                            <ul className="list-none m-0 p-0">
                                                                {
                                                                    applicantNotes.map((item: any, index: any) => {
                                                                        return (
                                                                            <>
                                                                                {
                                                                                    <li>
                                                                                        <a className="flex surface-border mb-3 p-3 border-1 surface-border border-round hover:surface-hover transition-colors transition-duration-150">
                                                                                            {item?.added_by?.profile_img != null && item?.added_by?.profile_img != "null" && item?.added_by?.profile_img != "" ?
                                                                                                <Avatar image={item?.added_by?.profile_img} shape="circle" />
                                                                                                :
                                                                                                <Avatar label={item?.added_by?.first_name.charAt(0).toUpperCase()} shape="circle" />
                                                                                            }
                                                                                            <div className="ml-3 flex-1">
                                                                                                <p className="font-semibold m-0">{item?.note}</p>
                                                                                                <div className="notes-info">
                                                                                                    <div className="notes-info-list"><i className="ti ti-clock"></i>{moment(item?.created_at).format("MMM DD, YYYY")} by {item?.added_by?.first_name} {item?.added_by?.last_name}</div>
                                                                                                </div>
                                                                                            </div>
                                                                                        </a>
                                                                                    </li>
                                                                                }
                                                                            </>
                                                                        )
                                                                    })
                                                                }
                                                            </ul>
                                                        </div>
                                                    </div>
                                                </div>
                                        }
                                        <div className="p-fluid formgrid grid">
                                            <div className="field col-12">
                                                <label htmlFor="name">Enter Note <span className="required">*</span></label>
                                                <InputTextarea
                                                    value={note}
                                                    onChange={(e) => setNote(e.target.value)}
                                                    rows={5}
                                                    cols={45}
                                                    placeholder='Enter Note'
                                                    className={errors['note'] && 'p-invalid'}
                                                />
                                                {errors['note'] !== undefined ? <small className="p-invalid-txt">{errors['note']}</small> : <></>}
                                            </div>
                                            <div className="field col-12">
                                                <div className="text-right">
                                                    {
                                                        isListening ?
                                                            <Button
                                                                className='p-button-danger'
                                                                onClick={stopListening}
                                                                icon="ti ti-microphone-off"
                                                            />
                                                            :
                                                            <Button
                                                                className='p-button-secondary'
                                                                onClick={startListening}
                                                                icon="ti ti-microphone"
                                                            />
                                                    }
                                                    <Button
                                                        className='ml-2'
                                                        onClick={onSaveNote}
                                                        icon="ti ti-check"
                                                        loading={saveNoteLoader}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {/* Ratings */}
                        <div className='grid'>
                            <div className="col-12 md:col-12">
                                <div className="card">
                                    <div className="card-header">
                                        <div className="card-title-box">
                                            <h3 className="card-title">Ratings</h3>
                                        </div>
                                    </div>
                                    <div className='card-body'>
                                        {
                                            otherUserFeedbacks.map((main_item: any, main_index: any) => {
                                                return (
                                                    <>
                                                        <p className='ml-2'><b>Added By {main_item.interviewer_name}</b></p>
                                                        <div className="grid col-12" key={main_index}>
                                                            {applicantRatingsTypes.map((item: any, index: any) => {
                                                                let currRating = main_item.items.find(
                                                                    (rating_item: any) => rating_item.type === item.code
                                                                );
                                                                return (
                                                                    <div className="col-12 md:col-2" key={index}>
                                                                        <p><b>{item.name}</b></p>
                                                                        <Rating
                                                                            value={!window.cn(currRating) && currRating !== undefined ? Number(currRating?.rating) : 0}
                                                                            cancel={false}
                                                                            disabled={true}
                                                                        />
                                                                    </div>
                                                                );
                                                            })}
                                                            {overAllRatingTemplate(main_item.items)}
                                                        </div>
                                                        <hr />
                                                    </>
                                                );
                                            })
                                        }
                                        <p className='ml-2'><b>Added By You</b></p>
                                        <div className="grid col-12">
                                            {
                                                applicantRatingsTypes.map((item: any, index: any) => {
                                                    let currRating = userFeedbacks.find((rating_item: any) => (rating_item.code === item.code));
                                                    return(
                                                        <div className="col-12 md:col-2">
                                                            <p><b>{item.name}</b></p>
                                                            <Rating value={!window.cn(currRating) && currRating !== undefined ? Number(currRating?.rating) : 0} onChange={(e) => onRatingChange(e.value, item.code, currRating)} cancel={false} />
                                                        </div>
                                                    )
                                                })
                                            }
                                            {overAllRatingTemplate(userFeedbacks)}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </>
                :
                    <></>
            }

            {
                applicantData?.resume !== undefined ?
                    <div className="card">
                        <div className="card-body">
                            <Worker workerUrl={`https://unpkg.com/pdfjs-dist@2.16.105/build/pdf.worker.min.js`}>
                                <Viewer
                                    fileUrl={applicantData?.resume}
                                    plugins={[zoomPluginInstance]}
                                    enableSmoothScroll={true}
                                />
                            </Worker>
                        </div>
                    </div>
                    :
                    <></>
            }

            {/* Add Dialog */}
            <Dialog
                visible={scheduleInterviewModal}
                style={{ width: '350px' }}
                className="p-fluid"
                header={"Schedule Interview For " + selectedRoundDetails?.stage_name}
                modal
                footer={
                    <>
                        <Button
                            label="Cancel"
                            className="p-button-secondary"
                            onClick={() => closeScheduleInterviewModal()}
                        />
                        <Button
                            label="Submit"
                            className="p-button-primary"
                            onClick={() => onSubmitScheduleInterview()}
                            loading={scheduleInterviewLoader}
                        />
                    </>
                }
                onHide={closeScheduleInterviewModal}
            >
                <div className="p-fluid formgrid grid">
                    <div className="field col-12 md:col-6">
                        <label htmlFor="interviewer_id">Select Interviewer <span style={{ color: "red" }}>*</span></label>
                        <Dropdown
                            value={scheduleInterviewData?.interviewer_id}
                            onChange={(e) => onInputChange(e, "interviewer_id")}
                            options={interviewers}
                            optionLabel="name"
                            placeholder="Select Interviewer"
                            className={errors['interviewer_id'] && 'p-invalid'}
                        ></Dropdown>
                        <small className="p-invalid-txt">{errors['interviewer_id']}</small>
                    </div>
                    <div className="field col-12 md:col-6">
                        <label htmlFor="scheduled_at">Select Interview Date & Time <span style={{ color: "red" }}>*</span></label>
                        <Calendar
                            id="calendar-12h"
                            value={scheduleInterviewData?.scheduled_at}
                            onChange={(e) => onInputChange(e, "scheduled_at")}
                            showIcon
                            showTime
                            hourFormat="12"
                            minDate={new Date()}
                            className={errors['scheduled_at'] && 'p-invalid'}
                        />
                        <small className="p-invalid-txt">{errors['scheduled_at']}</small>
                    </div>
                </div>
                
            </Dialog>
            
            {
                pageLoad && <Loader />
            }
        </>
    )
}