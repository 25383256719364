/********************  START APP API SLUG ************* */

// PREFIX
const ADMIN_PREFIX = "/api/admin/";

//Athencation and common routes
export const LOGIN = '/login';
export const ATTORNEY_LOGIN = '/attorney-login';
export const OTP_VERIFY = '/otp-verify';
export const CHANGE_PASSWORD = "/change-password";

// Dashboard
export const GET_DASHBOARD_DATA = ADMIN_PREFIX + "get-dashboard-data";
export const GET_NOTIFICATIONS = ADMIN_PREFIX + "get-notifications";
export const CLEAR_NOTIFICATION = ADMIN_PREFIX + "clear-notification";
export const GET_USER_ALL_NOTIFICATIONS = ADMIN_PREFIX + "get-all-user-notifications";
export const UPDATE_PROFILE_IMG = ADMIN_PREFIX + "update-profile-img";

// Users
export const GET_COUNTRIES = ADMIN_PREFIX + "get-countries";
export const ADD_UPDATE_CLIENT = ADMIN_PREFIX + "add-update-client";
export const GET_CLIENTS_LIST = ADMIN_PREFIX + "get-clients-list";
export const DELETE_CLIENT = ADMIN_PREFIX + "delete-client";
export const GET_SINGLE_CLIENT_DATA = ADMIN_PREFIX + "get-single-client-data";
export const APPROVE_REJECT_CLIENT = ADMIN_PREFIX + "approve-reject-client";
export const UPLOAD_BROCHURE = ADMIN_PREFIX + "upload-brochure";
export const SET_BROCHURE_DATA = "/set-brochure-data";
export const VIEW_FLIPBOOK = ADMIN_PREFIX + "view-flipbook";
export const INVESTMENT_MATERIAL_ACCESS = ADMIN_PREFIX + "investment-material-access";
export const GET_BROKERS_TO_ADD_TEAM = ADMIN_PREFIX + "get-broker-to-add-team";
export const ADD_TEAM_MEMBERS = ADMIN_PREFIX + "add-team-members";
export const GET_NOT_ASSIGNED_USER = ADMIN_PREFIX + "get-not-assigned-user";
export const ALLOW_REVOKE_DOWNLOAD_FILE = ADMIN_PREFIX + "allow-revoke-download-file";
export const ADD_USER_EXTRA_DETAILS = ADMIN_PREFIX + "add-user-extra-details";

// Careers
export const ADD_UPDATE_CAREER_JOBS = ADMIN_PREFIX + "add-update-career-jobs";
export const GET_CAREER_JOBS = ADMIN_PREFIX + "get-career-jobs";
export const GET_SINGLE_CAREER_JOB = ADMIN_PREFIX + "get-single-career-job";
export const CHANGE_CAREER_JOB_POST_STATUS = ADMIN_PREFIX + "change-career-job-post-status";

// Masters
export const ADD_UPDATE_JOB_ROLE = ADMIN_PREFIX + "add-update-job-role";
export const GET_JOB_ROLES = ADMIN_PREFIX + "get-job-roles";
export const GET_SINGLE_JOB_ROLE = ADMIN_PREFIX + "get-single-job-role";
export const CHANGE_JOB_ROLE_STATUS = ADMIN_PREFIX + "change-job-role-status";
export const GET_ALL_INVESTMENT_MATERIAL = ADMIN_PREFIX + "get-all-investment-material";
export const GET_SINGLE_INVESTMENT_MATERIAL = ADMIN_PREFIX + "get-single-investment-material";
export const ADD_INVESTMENT_MATERIAL = ADMIN_PREFIX + "add-investment-material";
export const GET_FILE_OR_FOLDER_DETAILS = ADMIN_PREFIX + "get-file-or-folder-details";
export const ADD_READ_TIME_OF_INVT_MATERIAL = ADMIN_PREFIX + "add-read-time-of-invt-material";
export const ADD_UPDATE_INVT_MATERIAL_FOLDER = ADMIN_PREFIX + "add-update-invt-material-folder";
export const GET_ALL_INVT_MATERIAL_FOLDER = ADMIN_PREFIX + "get-all-invt-material-folder";
export const GET_TRASHED_FOLDER_OR_FILE = ADMIN_PREFIX + "get-trashed-folders-or-files";
export const TRASH_OR_REVERT_FOLDER_OR_FILE = ADMIN_PREFIX + "trash-or-revert-folder-or-file";
export const DELETE_FOLDER = ADMIN_PREFIX + "delete-folder";
export const DELETE_INVESTMENT_MATERIAL = ADMIN_PREFIX + "delete-investment-material";
export const EMPTY_TRASH = ADMIN_PREFIX + "empty-trash";
export const GET_DRIVE_FOLDER_DETAILS = ADMIN_PREFIX + "get-drive-folder-details";
export const GET_PDF_DYNAMIC_DATA = ADMIN_PREFIX + "get-pdf-dynamic-data";
export const ADD_UPDATE_NDA = ADMIN_PREFIX + "add-update-nda";
export const LIST_ALL_NDA = ADMIN_PREFIX + "list-all-nda";
export const GET_SINGLE_NDA = ADMIN_PREFIX + "get-single-nda";
export const DELETE_NDA = ADMIN_PREFIX + "delete-nda";
export const UPLOAD_SIGNED_NDA = ADMIN_PREFIX + "upload-signed-nda";

// Applicants
export const GET_APPLICANTS_LIST = ADMIN_PREFIX + "get-applicants-list";
export const GET_SINGLE_APPLICANT_DATA = ADMIN_PREFIX + "get-single-applicant-data";
export const APPLICANT_STATUS_CHANGE = ADMIN_PREFIX + "applicant-status-change";
export const GET_APPLICANT_TRASH_LIST = ADMIN_PREFIX + "get-applicant-trash-list";
export const RESTORE_APPLICANT = ADMIN_PREFIX + "restore-applicant";
export const GIVE_APPLICANT_RATINGS = ADMIN_PREFIX + "give-applicant-ratings";
export const GET_APPLICANT_FEEDBACKS = ADMIN_PREFIX + "get-applicant-feedbacks";

// Investor Material
export const GET_INVESTOR_MATERIAL_DATA = ADMIN_PREFIX + "get-investor-material-data";
export const VIEW_INVESTMENT_MATERIAL = ADMIN_PREFIX + "view-investment-material";

// Investment Approval
export const GET_BROKER_INVT_LIST_DROPDOWN = ADMIN_PREFIX + "get-broker-invt-list-dropdown";
export const GET_ALL_INVESTMENT_APPROVAL_MATERIAL = ADMIN_PREFIX + "get-all-investment-approval-material";
export const APPROVE_INVESTMENT_MATERIAL_ACCESS = ADMIN_PREFIX + "approve-investment-material-access";

// Leads
export const GET_ASSIGNED_USERS = ADMIN_PREFIX + "get-assigned-users";
export const ADD_UPDATE_LEAD = ADMIN_PREFIX + "add-update-lead";
export const UPDATE_LEADS_DATA = ADMIN_PREFIX + "update-leads-data";
export const ADD_LEADS_BY_EXCEL = ADMIN_PREFIX + "add-leads-by-excel";
export const GET_ZOHO_FORM_FIELDS = ADMIN_PREFIX + "get-zoho-form-fields";
export const GET_LEADS = ADMIN_PREFIX + "get-leads";
export const GET_PERSONAL_LEADS = ADMIN_PREFIX + "get-personal-leads";
export const GET_SINGLE_LEAD = ADMIN_PREFIX + "get-single-lead";
export const GET_LEADS_LIST_FILTER = ADMIN_PREFIX + "get-leads-list-filter";
export const KANBAN_LEADS_DATA = ADMIN_PREFIX + "kanban-leads-data";
export const KANBAN_LEAD_STAGE_CHANGE = ADMIN_PREFIX + "kanban-lead-stage-change";
export const ADD_LEAD_NOTES = ADMIN_PREFIX + "add-lead-notes";
export const GET_LEAD_NOTES = ADMIN_PREFIX + "get-lead-notes";

// Campaigns
export const GET_INSTANTLY_CAMPAIGNS = ADMIN_PREFIX + "get-instantly-campaigns";
export const GET_CAMPAIGN_DETAILS = ADMIN_PREFIX + "get-campaign-details";

// SMS Campaigns
export const GET_SMS_CAMPAIGNS = ADMIN_PREFIX + "get-sms-campaigns";
export const ADD_UPDATE_SMS_CAMPAIGN = ADMIN_PREFIX + "add-update-sms-campaign";
export const ADD_EXTRA_PEOPLE_TO_CAMPAIGN = ADMIN_PREFIX + "add-extra-people-to-campaign";
export const ADD_NEW_MESSAGE_TO_CAMPAIGN = ADMIN_PREFIX + "add-new-message-to-campaign";
export const ADD_SPECIFIC_RECIPIENTS_TO_MESSAGE = ADMIN_PREFIX + "add-specific-recipients-to-message";
export const GET_INVESTORS_LIST_FOR_CAMPAIGNS = ADMIN_PREFIX + "get-investors-list-for-campaign";
export const GET_SMS_CAMPAIGN_DETAILS = ADMIN_PREFIX + "get-sms-campaign-details";
export const GET_TWILIO_NUMBERS = ADMIN_PREFIX + "get-twilio-numbers";
export const GET_TIMEZONES = ADMIN_PREFIX + "get-timezones";
export const DELETE_SMS_CAMPAIGN = ADMIN_PREFIX + "delete-sms-campaign";
export const GET_EDIT_CAMPAIGN_DATA = ADMIN_PREFIX + "get-edit-campaign-data";
export const GET_MESSAGE_RECIPIENT = ADMIN_PREFIX + "get-message-recipient";
export const REMOVE_RECIPIENT_FROM_CAMPAIGN = ADMIN_PREFIX + "remove-recipient-from-campaign";

// Task
export const ADD_TASK = ADMIN_PREFIX + "add-task";
export const GET_TASKS = ADMIN_PREFIX + "get-tasks";
export const CHANGE_TASK_STATUS = ADMIN_PREFIX + "change-task-status";

// Role Management
export const ADD_UPDATE_ROLE = ADMIN_PREFIX + "add-update-role";
export const GET_ALL_ROLES = ADMIN_PREFIX + "get-all-roles";
export const GET_SINGLE_ROLE = ADMIN_PREFIX + "get-single-role";

// Employee
export const ADD_UPDATE_EMPLOYEE = ADMIN_PREFIX + "add-update-employee";
export const GET_ALL_EMPLOYEE = ADMIN_PREFIX + "get-all-employee";
export const GET_SINGLE_EMPLOYEE = ADMIN_PREFIX + "get-single-employee";
export const ADD_UPDATE_LEAVE_TYPE = ADMIN_PREFIX + "add-update-leave-type";
export const GET_ALL_LEAVE_TYPE = ADMIN_PREFIX + "get-all-leave-type";
export const GET_SINGLE_LEAVE_TYPE = ADMIN_PREFIX + "get-single-leave-type";
export const CHANGE_LEAVE_TYPE_STATUS = ADMIN_PREFIX + "change-leave-type-status";
export const ADD_UPDATE_LEAVE = ADMIN_PREFIX + "add-update-leave";
export const GET_ALL_LEAVES = ADMIN_PREFIX + "get-all-leaves";
export const GET_SINGLE_LEAVE = ADMIN_PREFIX + "get-single-leave";

// Convert investor to doc
export const ADD_UPDATE_CONVERT_INVESTOR_DOC = ADMIN_PREFIX + "add-update-convert-investor-doc";
export const GET_ALL_CONVERT_INVESTOR_DOC = "/api/get-all-convert-investor-doc";
export const GET_SINGLE_CONVERT_INVESTOR_DOC = ADMIN_PREFIX + "get-single-convert-investor-doc";
export const DELETE_CONVERT_INVESTOR_DOC = ADMIN_PREFIX + "delete-convert-investor-doc";
export const UPLOAD_INVESTOR_DOC = ADMIN_PREFIX + "upload-investor-doc";
export const GET_INVESTOR_UPLOADED_DOCS = "/api/get-investor-uploaded-docs";
export const ADD_INVESTOR_ATTORNEY = ADMIN_PREFIX + "add-investor-attorney";
export const GET_INVESTOR_ATTORNEY = ADMIN_PREFIX + "get-investor-attorney";

// Request for doc
export const ADD_REQUEST_FOR_DOC = ADMIN_PREFIX + "add-request-for-doc";

// Interview
export const ADD_UPDATE_INTERVIEW_STAGE_MASTER = ADMIN_PREFIX + "add-update-interview-stage-master";
export const GET_ALL_INTERVIEW_STAGES_MASTER = ADMIN_PREFIX + "get-all-interview-stages-master";
export const CHANGE_INTERVIEW_STAGES_MASTER_STATUS = ADMIN_PREFIX + "change-interview-stages-master-status";

// Schedule Interview
export const GET_INTERVIEWERS_LIST = ADMIN_PREFIX + "get-interviewers-list";
export const SCHEDULE_INTERVIEW = ADMIN_PREFIX + "schedule-interview";
export const GET_SCHEDULED_INTERVIEWS = ADMIN_PREFIX + "get-scheduled-interview";
export const GET_INTERVIEWS_LIST = ADMIN_PREFIX + "interviews-list";
export const GET_INTERVIEW_DETAIL = ADMIN_PREFIX + "get-interview-detail";
export const GIVE_INTERVIEW_RESULT = ADMIN_PREFIX + "give-interview-result";
export const ADD_UPDATE_INTERVIEW_NOTES = ADMIN_PREFIX + "add-update-interview-notes";
export const GET_APPLICANT_NOTES = ADMIN_PREFIX + "get-applicant-notes";