import { useEffect, useState, useRef } from 'react';
import { useNavigate, Link } from 'react-router-dom';

//Prime React Component Inbuilt
import { Toast } from 'primereact/toast';
import { Column } from 'primereact/column';
import { DataTable } from 'primereact/datatable';
import { BreadCrumb } from 'primereact/breadcrumb';
import { Badge } from 'primereact/badge';
import { Button } from 'primereact/button';
import { Rating } from 'primereact/rating';
import { Dialog } from 'primereact/dialog';
import { Toolbar } from 'primereact/toolbar';
import { InputText } from 'primereact/inputtext';

//Services
import PageService from '../../../service/PageService';
import { SkeletonbodyTemplate, Skeletonitems, defaultPageRowOptions, defaultRowOptions, paginatorLinks, showingEntries } from '../../../appconfig/Settings';
import { InterviewsListColumns } from '../../../appconfig/DatatableSetting';
import { Avatar } from 'primereact/avatar';
import { convertDatetimeFormat } from '../../../appconfig/AppHelper';

export const InterviewList = () => {
    document.title = "Applicant Details | Venture Studio"

    //BreadCrumb
    const items = [
        {
            label: 'InputText',
            template: () => <span className="p-breadcrumb-item active">Dashboard</span>
        },
        {
            label: 'InputText',
            template: () => <Link className="p-breadcrumb-item" to="/">Interviews</Link>
        }
    ];
    const home = { icon: 'pi pi-home', url: '/dashboard' }

    //Navigate Another Route
    const navigate = useNavigate();

    //Set Toast/ Filter Properties
    const [globalFilter, setGlobalFilter] = useState<any>(null);
    const toast = useRef<any>(null);

    const pageService = new PageService();

    const [pageLoad, setPageLoad] = useState<boolean>(false);
    const [interviewsList, setInterviewsList] = useState<any>([]);

    // useEffect
    useEffect(() => {
        getInterviewsListFromAPI();
    }, []);

    // Get interviews list
    const getInterviewsListFromAPI = () => {
        // Api call
        pageService
            .getInterviewsList(localStorage.getItem("id"))
            .then((response) => {
                // Get response
                if (response) {
                    setInterviewsList(response);
                    setPageLoad(true);
                } else {
                    setInterviewsList([]);
                    setPageLoad(true);
                }
            });
    };

    // left part of toolbar
    const leftToolbarTemplate = () => {
        return (
            <>
                <span className="block mt-2 md:mt-0 p-input-icon-left">
                    <i className="pi pi-search" />
                    <InputText
                        type="search"
                        onInput={(e) => setGlobalFilter(e.currentTarget.value)}
                        placeholder="Search..."
                    />
                </span>
            </>
        );
    };

    // Template for actions
    const actionBodyTemplate = (rowData: any, rowIndex: any) => {
        return (
            <div className="tb-actions">
                <Button
                    icon="pi pi-file"
                    className="p-button-square p-btn-default"
                    onClick={() => window.open(rowData.resume, "_blank")}
                    tooltip="Resume" 
                    tooltipOptions={{ position: 'top' }}
                />
            </div>
        )
    };

    // Template for applicant name
    const applicantNameTemplate = (rowData: any, rowIndex: any) => {
        return(
            <>
                <Link className="tb-avatar-box" to={"/interview/details/" + rowData.applicant_id}><Avatar className='tb-avatar-img user-list-avatar' label={rowData?.applicant_name.charAt(0).toUpperCase()} shape="circle" /><div className="tb-avatar-info"><div className="tb-avatar-name">{rowData?.applicant_name}</div></div></Link>
            </>
        )
    };

    // Template for scheduled at
    const roundsTemplate = (rowData: any, rowIndex: any) => {
        return (
            <>
                {
                    rowData.rounds.map((item: any, index: any) => {
                        return (
                            <>
                                <p>{item.round}</p>
                                <div className='flex' style={{ justifyContent: "space-between", alignItems: "baseline" }}>
                                    <p>{<Badge severity={item.status === "Pending" ? "warning" : item.status === "Failed" ? "danger" : "success"} value={item.status} />}</p>
                                    <p>{convertDatetimeFormat(item.scheduled_at)}</p>
                                </div>
                                {rowData.rounds.length > 1 ? <hr /> : ""}
                            </>
                        );
                    })
                }
            </>
        )
    };

    return(
        <>
            <Toast ref={toast} />
            <div className="page-header">
                <div className="page-leftheader">
                    <div className="page-header-info">
                        <div className="page-title"> Interviews</div>
                        <div className="main-content-breadcrumb">
                            <BreadCrumb model={items} home={home} />
                        </div>
                    </div>
                </div>
                <div className="page-rightheader">
                    <div className="btn-icon-list">
                        <div className="page-header-search">
                            <div className="p-toolbar page-header-search-area">
                                <div className="btn-icon-list">
                                    <div className="page-header-search">
                                        <Toolbar className="page-header-search-area" left={leftToolbarTemplate}></Toolbar>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="grid crud-demo">
                <div className="col-12">
                    <div className="card">
                        <div className="card-body">
                            {/* Datatable Start */}
                            {pageLoad == true ? (
                                <>
                                    <DataTable
                                        className="datatable-responsive" stripedRows
                                        value={interviewsList}
                                        paginator={interviewsList.length > 0 && true}
                                        globalFilter={globalFilter}
                                        rows={defaultRowOptions}
                                        rowsPerPageOptions={defaultPageRowOptions}
                                        paginatorTemplate={paginatorLinks}
                                        currentPageReportTemplate={showingEntries}
                                        emptyMessage="No Interviews Found"
                                    >
                                        {InterviewsListColumns.map((col, i) => {
                                            if (col.field === 'action') {
                                                return (
                                                    <Column
                                                        key={col.field}
                                                        field={col.field}
                                                        header={col.header}
                                                        body={actionBodyTemplate}
                                                    />
                                                );
                                            } else if (col.field === 'applicant_name') {
                                                return (
                                                    <Column
                                                        key={col.field}
                                                        field={col.field}
                                                        header={col.header}
                                                        body={applicantNameTemplate}
                                                    />
                                                );
                                            } else if (col.field === 'rounds') {
                                                return (
                                                    <Column
                                                        key={col.field}
                                                        field={col.field}
                                                        header={col.header}
                                                        body={roundsTemplate}
                                                    />
                                                );
                                            } else if (col.field === 'sr_no') {
                                                return (
                                                    <Column
                                                        key={col.field}
                                                        field={col.field}
                                                        header={col.header}
                                                        body={(_, { rowIndex }) => rowIndex + 1}
                                                    />
                                                );
                                            } else {
                                                return (
                                                    <Column
                                                        key={col.field}
                                                        field={col.field}
                                                        header={col.header}
                                                        sortable
                                                        filter
                                                    />
                                                );
                                            }
                                        })}
                                    </DataTable>
                                </>
                            ) : (
                                <>
                                    {/* Skeleton Data table */}
                                    <DataTable value={Skeletonitems}>
                                        {InterviewsListColumns.map((col, i) => (
                                            <Column
                                                key={col.field}
                                                field={col.field}
                                                header={col.header}
                                                body={SkeletonbodyTemplate}
                                            />
                                        ))}
                                    </DataTable>
                                </>
                            )}
                        </div>
                        {/* Datatable End */}
                    </div>
                </div>
            </div>
        </>
    )
};

